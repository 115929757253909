import classNames from 'classnames';
import Loader from '../loader';
import styles from './index.module.scss';

interface LoaderOverlayProps {
    displayedText?: string;
    wrapperClassName?: string;
    loaderClassName?: string;
}

const LoaderOverlay = ({ displayedText, wrapperClassName, loaderClassName }: LoaderOverlayProps) => (
    <div className={classNames(styles.container, {
        [wrapperClassName]: !!wrapperClassName,
    })}>
        <div className={styles.backDrop} />

        <div className={styles.loaderWrapper}>
            {displayedText && <h4>{displayedText}</h4>}
            <Loader
                className={classNames(styles.loader, {
                    [loaderClassName]: !!loaderClassName,
                })}
            />
        </div>
    </div>
);

LoaderOverlay.defaultProps = {
    displayedText:    false,
    wrapperClassName: undefined,
    loaderClassName:  undefined,
};

export default LoaderOverlay;

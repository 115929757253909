import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import QrReader from 'react-qr-reader';
import { Link, useNavigate } from 'react-router-dom';

import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import TAOOHelmet from 'components/helmet/Helmet';
import useLocale from 'hooks/locale/useLocale';
import useTransaction from 'hooks/transaction/useTransaction';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { isUrl } from 'services/url';
import { isValidNumber } from 'services/validation';

import styles from './index.module.scss';
import Popup from './popup';
import { Config } from 'config';
import useQueryTransaction from 'hooks/transaction/useQueryTransaction';
import ScanQueryPopup from './query-popup/ScanQueryPopup';
import Loader from 'components/loaders/loader';
import LoaderOverlay from 'components/loaders/loader-overlay';

const wheelGameReferralIDs = ['23', '24', '22', '21', '14'];
const wheelGameQRCodesToReferralIDs = {
    '6SNvRQj': '23',
    '6SNvRQi': '24',
    '6SNvRQh': '22',
    '6SNvRQg': '21',

    // for dev test
    '6SNvRQf': '14',
}

const IsAWheelGameReferrals = (link) => {
    for (const referral of wheelGameReferralIDs) {
        if (link.includes(`/rs/${ referral }`)) {
            return referral;
        }
    }

    if(link.includes('Image\n\n')) {
        const splitRes = link.split(' ');
        const newLink = splitRes[splitRes.length - 1];
        const referralCodes = Object.keys(wheelGameQRCodesToReferralIDs);

        for(const code of referralCodes) {
            if(newLink === `${Config.qrAppDomain}/${code}`) {
                return wheelGameQRCodesToReferralIDs[code];
            }
        }
    }

    return false;
};

const isQueryUrl = (url) => {
    if(!isUrl(url) || !url.includes('/q/')) {
        return { success: false, data: null };
    }

    const tokenBase64 = url.split('/').at(-1);

    try {
        const decodedToken = atob(tokenBase64);
        const data = JSON.parse(decodedToken);

        if(!data?.token || !data?.version || !data?.route) {
            throw new Error('Invalid query data');
        }
        
        return { success: true, data: { ...data, base64: tokenBase64 } };
    } catch(e) {
        console.error(e);
        return { success: false, data: null };
    }
}
//http://localhost:5173/q/eyJ2ZXJzaW9uIjoiMi4wIiwicm91dGUiOiJTQUxFX1BPSU5UIiwidG9rZW4iOiI5YmI3ODdlYi1mYTY1LTQzNmUtYTVlMi1hZDNiZGY2MTc0OWUifQ
function Scan() {
    const navigate = useNavigate();
    const intl = useIntl();
    const { attach } = useTransaction();
    const queryTransaction = useQueryTransaction();
    const lang = useLocale();

    const [closeQr, setCloseQr] = useState(false);
    const [scanData, setScanData] = useState(null);
    const [queryData, setQueryData] = useState(null);
    const [shouldShowQueryPopup, setShouldShowQueryPopup] = useState(false);
    const [isRead, setIsRead] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleScan = async (val) => {
        if (!val) {
            return;
        }


        const queryUrl = isQueryUrl(val);

        if(queryUrl.success && !isRead && !isLoading) {
            setIsRead(true);
            setIsLoading(true);

            const data = await queryTransaction.getCards(queryUrl.data.token);

            if(!data) {
                setCloseQr(true);
                navigate(-1);
                setIsLoading(false);

                return
            }

            setQueryData({ data, token: queryUrl.data.token });
            setCloseQr(true);
            setIsLoading(false);
            setShouldShowQueryPopup(true);

            return
        }

        if(isUrl(val)) {
            const storeId = IsAWheelGameReferrals(val);
    
            if (
                storeId && 
                isValidNumber(storeId) && 
                wheelGameReferralIDs.includes(storeId)
            ) {
                localStorage.setItem('referralStoreId', storeId);
                navigate(All_PATHS_NAMESPACES[lang]['wheel-game'].path);
    
                return;
            }
        }

        if (!scanData && !isRead && !isLoading) {
            setIsRead(true);
            setIsLoading(true);

            const data = await attach(val);

            if (!data) {
                setCloseQr(true);
                setIsLoading(false);
                navigate(-1);

                return;
            }

            setScanData(data);
            setIsLoading(false);
            setCloseQr(true);
        }
    };

    const handleError = (error) => {
        if (error) {
            console.error(error);
        }
    };

    return (
        <PageTransitionWrapper className="flex-grow-1 pos-relative d-flex flex-column">
            <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.scan' })} />

            <div className={`${ styles.qr_wrapper } flex-grow-1`}>
                {!closeQr && (
                    <>
                        <Link to={All_PATHS_NAMESPACES[lang].home.path} className={styles.cancel_payment}>
                            <FormattedMessage id="scan.cancel-scanning" />
                        </Link>

                        <div className={styles.qr_code_description}>
                            <FormattedMessage id="scan.align-code-width-frame" />
                        </div>

                        {isLoading && <LoaderOverlay />}

                        <QrReader
                            onScan={handleScan}
                            onError={handleError}
                            style={{
                                width:  '100%',
                                height: 'calc(var(--vh, 1vh) * 100)',
                            }}
                            videoContainerStyle={{
                                width:  '100%',
                                height: 'calc(var(--vh, 1vh) * 100)',
                            }}
                            videoStyle={{
                                width:  '100%',
                                height: 'calc(var(--vh, 1vh) * 100)',
                            }}
                            constraints={{
                                facingMode: 'environment',
                            }}
                        />
                        <div className={styles.scan_square}>
                            <div className={styles.shadow} />
                            <div className={styles.square} />
                            <div className={styles.shadow} />
                        </div>

                        <div className={styles.or_manually_container}>
                            <div className={styles.or}>
                                <FormattedMessage id="scan.or" />
                            </div>
                            <Link to={All_PATHS_NAMESPACES[lang].home.path} className={styles.add_manually}>
                                <FormattedMessage id="scan.add-manually" />
                            </Link>
                        </div>
                    </>
                )}
                <AnimatePresence>{closeQr && scanData && <Popup data={scanData} />}</AnimatePresence>
                <ScanQueryPopup 
                    shouldShow={shouldShowQueryPopup}
                    cards={queryData?.data}
                    token={queryData?.token}
                    onClose={() => setShouldShowQueryPopup(false)}
                />
            </div>
        </PageTransitionWrapper>
    );
}

export default Scan;
